import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid min-w-[720px] place-items-center p-6 border-2 border-gray-200/80 border-dashed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('add')), ["stop"])),
      class: "absolute bottom-2 -left-8 h-6 w-6 rounded-full bg-white flex justify-center items-center border border-gray-200 text-gray-200 hover:text-white hover:bg-blue cursor-pointer transition"
    }, [
      _createVNode(_component_SVG, {
        name: "icons/add",
        class: "w-3 h-3"
      })
    ], 512), [
      [_vShow, !_ctx.large]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('add')), ["stop"])),
        class: "btn cursor-pointer inline-flex items-center py-[10px]"
      }, [
        _createVNode(_component_SVG, {
          name: "icons/add",
          class: "w-4 h-4 mr-2"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ])
    ], 512), [
      [_vShow, _ctx.large]
    ])
  ], 64))
}