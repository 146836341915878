import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    id: _ctx.id,
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.drag && _ctx.drag(...args))),
    class: _normalizeClass(["table-row border-b border-background text-gray-500 bg-white hover:bg-gray-50 text-sm cursor-pointer select-none duration-100 transition", { 'opacity-50': _ctx.isDragging }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 42, _hoisted_1))
}