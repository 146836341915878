
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
	props: {
		id: String,
		draggable: Boolean,
	},

	emits: ['drag', 'drop'],

	setup(props, { emit }) {
		const isDragging = ref(false);

		let debounce = 0;

		onMounted(() => {
			window.addEventListener('mouseup', dropEvent);
		});

		onUnmounted(() => {
			window.removeEventListener('mouseup', dropEvent);
		});

		function dropEvent(e: MouseEvent) {
			clearTimeout(debounce);

			if (!isDragging.value) {
				return;
			}

			isDragging.value = false;

			emit('drop', e.target);
		}

		function drag() {
			if (!props.draggable) {
				return;
			}

			debounce = setTimeout(() => {
				isDragging.value = true;

				emit('drag');
			}, 100);
		}

		return {
			// data
			isDragging,

			// functions
			drag,
		};
	},
});
