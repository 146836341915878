import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-1 w-[38%] min-w-[38%]" }
const _hoisted_2 = { class: "w-[17%] min-w-[17%]" }
const _hoisted_3 = { class: "w-[13%] min-w-[13%]" }
const _hoisted_4 = { class: "w-[13%] min-w-[13%]" }
const _hoisted_5 = { class: "w-[5%] min-w-[5%]" }
const _hoisted_6 = { class: "w-[14%] min-w-[14%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInput = _resolveComponent("UserInput")!
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_StatusSelect = _resolveComponent("StatusSelect")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    onBlur: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
    onKeydown: _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.close()), ["esc"])),
    class: "flex items-center w-full border-b border-background text-gray-500 bg-white hover:bg-gray-50 text-sm cursor-pointer select-none duration-100 transition"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)), ["enter"])),
        ref: "input",
        class: "py-2 px-3 w-full pl-10 text-gray bg-transparent text-sm font-bold rounded-md outline-none"
      }, null, 544), [
        [_vModelText, _ctx.form.title]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UserInput, {
        modelValue: _ctx.form.responsible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.responsible) = $event)),
        onUpdate: _cache[3] || (_cache[3] = (event) => (_ctx.form.responsible = event)),
        restricted: true,
        max: 1,
        borderless: true,
        noMargin: true
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DateInput, {
        modelValue: _ctx.form.expectedStart,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.expectedStart) = $event)),
        borderless: true,
        "no-icon": true
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DateInput, {
        modelValue: _ctx.form.expectedEnd,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.expectedEnd) = $event)),
        min: _ctx.form.expectedStart,
        borderless: true,
        "no-icon": true
      }, null, 8, ["modelValue", "min"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Select, {
        modelValue: _ctx.form.point,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.point) = $event)),
        options: [1, 2, 3, 5, 8, 13, 21, 34].map((point) => ({ key: point, value: point })),
        borderless: true,
        noMin: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_StatusSelect, {
        modelValue: _ctx.form.task_status_id,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.task_status_id) = $event)),
        borderless: true,
        onKeydown: _cache[8] || (_cache[8] = ($event: any) => {
					if (!$event.ctrlKey && $event.keyCode === 9) {
						_ctx.add();
						$event.preventDefault();
					}
				})
      }, null, 8, ["modelValue"])
    ])
  ], 544)), [
    [_vShow, _ctx.active],
    [_directive_click_away, _ctx.clickAway]
  ])
}