
import { defineComponent, nextTick, reactive, Ref, ref, watch } from 'vue';

import TableRow from '@/components/table/Row.vue';
import UserInput from '@/components/form/UserInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Select from '@/components/form/Select.vue';
import StatusSelect from '@/components/form/StatusSelect.vue';

interface Form {
	title: string;
	responsible: number[];
	expectedStart: Date | null;
	expectedEnd: Date | null;
	point: number;
	task_status_id: number | null;
}

export default defineComponent({
	props: {
		active: Boolean,
		duplicate: Object,
	},

	emits: ['add', 'update:active', 'update:duplicate'],

	components: {
		TableRow,
		UserInput,
		DateInput,
		Select,
		StatusSelect,
	},

	setup(props, { emit }) {
		const form: Form = reactive({
			title: '',
			responsible: [],
			expectedStart: null,
			expectedEnd: null,
			point: 1,
			task_status_id: 1,
		});

		const input: Ref = ref(null);

		watch(
			() => props.duplicate,
			(value) => {
				if (value) {
					emit('update:active', true);
					emit('update:duplicate', null);

					form.title = `${value.title} (avrit)`;
					form.responsible = value.responsible_user_id ? [value.responsible_user_id] : [];
					form.expectedStart = value.expected_start ? new Date(value.expected_start) : null;
					form.expectedEnd = value.expected_end ? new Date(value.expected_end) : null;
					form.point = value.point;
					form.task_status_id = value.task_status_id;
				}
			}
		);

		watch(
			() => props.active,
			(value) => {
				if (value) {
					nextTick(() => {
						input.value.focus();
					});
				}
			}
		);

		// Expected end cannot be before expected start
		watch(
			() => form.expectedStart,
			(value) => {
				if (!form.expectedEnd) {
					return;
				}

				if (value !== null && value > form.expectedEnd) {
					form.expectedEnd = form.expectedStart;
				}
			}
		);

		function clear() {
			form.title = '';
			form.responsible = [];
			form.expectedStart = null;
			form.expectedEnd = null;
			form.point = 1;
			form.task_status_id = 1;
		}

		function close() {
			if (!props.active) {
				return;
			}

			clear();

			emit('update:active', false);
		}

		async function add() {
			if (!form.title) {
				return;
			}

			emit('add', form);

			close();

			nextTick(() => {
				emit('update:active', true);
			});
		}

		function clickAway() {
			if (!form.title) {
				close();

				return;
			}

			add();
		}

		return {
			input,
			form,

			// functions
			close,
			add,
			clickAway,
		};
	},
});
