import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withKeys as _withKeys, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-807806f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { class: "group relative flex items-center w-[38%] min-w-[38%] py-1.5 px-4 font-bold whitespace-nowrap" }
const _hoisted_4 = { class: "ml-2 overflow-x-hidden overflow-ellipsis max-w-full" }
const _hoisted_5 = { class: "absolute right-0 top-1/2 transform -translate-y-1/2 flex bg-gray-50 opacity-0 group-hover:opacity-100" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "py-1.5 px-4 w-[17%] min-w-[17%] whitespace-nowrap" }
const _hoisted_9 = {
  key: 0,
  class: "overflow-x-hidden overflow-ellipsis max-w-full"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "py-1.5 px-4 w-[13%] min-w-[13%] whitespace-nowrap" }
const _hoisted_12 = { class: "py-1.5 px-4 w-[13%] min-w-[13%] whitespace-nowrap" }
const _hoisted_13 = { class: "py-1.5 px-4 w-[5%] min-w-[5%] whitespace-nowrap" }
const _hoisted_14 = { class: "py-1.5 px-4 w-[14%] min-w-[14%] text-right whitespace-nowrap" }
const _hoisted_15 = { class: "absolute -left-8 top-1/2 transform -translate-y-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_SVG = _resolveComponent("SVG")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_AddTask = _resolveComponent("AddTask")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock(_component_Table, {
    title: _ctx.title,
    "onUpdate:title": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.title) = $event)),
    headings: [
			`${_ctx.tasks.length} ${_ctx.tasks.length == 1 ? 'Uppgáva' : 'Uppgávur'}`,
			'Ábyrgd',
			'Byrjan',
			'Endi',
			'Stig',
			'Status',
		],
    add: _ctx.shouldAdd,
    empty: _ctx.tasks == 0,
    "multi-select": true,
    "can-edit-title": _ctx.$user.can('update tasks') && !!_ctx.group.id,
    "can-delete": _ctx.$user.can('delete tasks'),
    shouldSelectAll: _ctx.shouldSelectAll,
    onSelectAll: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectAll($event, _ctx.group))),
    onAdd: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isAdding = true)),
    onUpdateTitle: _ctx.renameGroup,
    onDelete: _ctx.deleteGroup
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: `group-${_ctx.group.id}`,
        class: "relative"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
          return (_openBlock(), _createElementBlock("div", {
            key: task.id,
            id: `task-${task.id}`,
            onClick: ($event: any) => (_ctx.$emit('open-overlay', task)),
            class: _normalizeClass([{ 'draggable-selected': task.checked }, "flex items-center w-full border-b border-background text-gray-500 bg-white hover:bg-gray-50 text-sm cursor-pointer select-none duration-100 transition"])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Checkbox, {
                modelValue: task.checked,
                "onUpdate:modelValue": ($event: any) => ((task.checked) = $event),
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                onToggle: ($event) => _ctx.handleChecked(task.id, $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onToggle"]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(task.title), 1),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.$user.can('update tasks'))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _withModifiers(($event: any) => (_ctx.duplicateTask(task)), ["stop"]),
                      class: "w-5 h-5 p-[2px] text-blue hover:text-blue-700 cursor-pointer",
                      title: "Kopiera uppgávu"
                    }, [
                      _createVNode(_component_SVG, { name: "icons/copy" })
                    ], 8, _hoisted_6))
                  : _createCommentVNode("", true),
                (_ctx.$user.can('delete tasks'))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _withModifiers(($event: any) => (_ctx.deleteTask(task.id, _ctx.group)), ["stop"]),
                      class: "w-5 h-5 p-[2px] text-red hover:text-red-700 cursor-pointer",
                      title: "Strika uppgávu"
                    }, [
                      _createVNode(_component_SVG, { name: "icons/trash" })
                    ], 8, _hoisted_7))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (task.responsible_user_id)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.getUserFullName(_ctx.getUser(task.responsible_user_id))), 1))
                : _createCommentVNode("", true),
              (!task.responsible_user_id)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, _toDisplayString(task.expected_start ? new Date(task.expected_start).format('%-d. %mn3. %y') : '-'), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(task.expected_end ? new Date(task.expected_end).format('%-d. %mn3. %y') : '-'), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(task.point ?? '-'), 1),
            _createElementVNode("div", _hoisted_14, [
              (task.status)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "inline-block py-1 px-2 text-xs font-bold rounded",
                    style: _normalizeStyle(`color: ${_ctx.handleStatusColor(task.status)}; background-color: ${_ctx.handleStatusColor(
							task.status
						)}38;`)
                  }, _toDisplayString(_ctx.handleStatusTitle(task.status)), 5))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", null, "-", 512), [
                [_vShow, !task.status]
              ])
            ])
          ], 10, _hoisted_2))
        }), 128)),
        _withDirectives(_createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Loader, { class: "w-4 h-4" })
        ], 512), [
          [_vShow, _ctx.dragLoader]
        ]),
        _withDirectives(_createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-white-500 text-dark-500 font-bold opacity-50 z-20 flex items-center justify-center text-lg" }, _toDisplayString(_ctx.dragLoader ? '' : 'Legg afturat bólki'), 513), [
          [_vShow, _ctx.dragLoader || _ctx.isDraggedOver === `group-${_ctx.group.id}`]
        ])
      ], 8, _hoisted_1),
      (_ctx.$user.can('create tasks'))
        ? (_openBlock(), _createBlock(_component_AddTask, {
            key: 0,
            active: _ctx.isAdding,
            "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAdding) = $event)),
            duplicate: _ctx.duplicate,
            "onUpdate:duplicate": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.duplicate) = $event)),
            onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.isAdding = true), ["enter"])),
            onAdd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addTask($event)))
          }, null, 8, ["active", "duplicate"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "headings", "add", "empty", "can-edit-title", "can-delete", "shouldSelectAll", "onUpdateTitle", "onDelete"]))
}