
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';

import Select from '@/components/form/Select.vue';
import TaskStatus from '@/models/task-status.model';
import TaskStatusService from '@/services/task-status.service';
import { useRoute } from 'vue-router';

type Option = {
	key: number;
	value: string;
};

export default defineComponent({
	props: ['modelValue', 'borderless'],

	components: {
		Select,
	},

	setup(props, ctx) {
		const selected: Ref = ref(null);
		const statuses: Ref<TaskStatus[]> = ref([]);
		const options: Ref<Option[]> = ref([]);

		const route = useRoute();

		onMounted(async () => {
			if (route.params.id) {
				await TaskStatusService.index(Number(route.params.id)).then((response) => {
					if (response) {
						statuses.value = response;
					}
				});

				statuses.value.forEach((status: TaskStatus) => {
					if (!status.color) {
						status.color = 'default color';
					}

					const div_string = `<div class="inline-block py-1 px-2 text-xs font-bold rounded" style="color: ${status.color}; background-color: ${status.color}38;">${status.title}</div>`;

					options.value.push({ key: status.id, value: div_string });
				});

				load();
			}
		});

		watch(
			() => props.modelValue,
			() => {
				load();
			}
		);

		function load() {
			if (isNaN(props.modelValue)) {
				return;
			}

			if (props.modelValue == 0) {
				selected.value = options.value[0].key;
				return;
			}

			selected.value = props.modelValue;
		}

		watch(selected, (value: number) => {
			const status = statuses.value.find((status: TaskStatus) => status.id == value);

			ctx.emit('update:modelValue', status);
		});

		return {
			// data
			selected,
			statuses,
			options,
		};
	},
});
