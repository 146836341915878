
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import Store from '@/store';

import SVG from '@/components/SVG.vue';
import User from '@/models/user.model';
import ProjectService from '@/services/project.service';

export default defineComponent({
	props: [
		'modelValue',
		'label',
		'setFocus',
		'placeholder',
		'max',
		'borderless',
		'noMargin',
		'disabled',
		'restricted',
	],

	emits: ['update', 'add', 'remove'],

	components: {
		SVG,
	},

	setup(props, ctx) {
		const user = Store.get('user');
		const route = useRoute();
		// input element ref
		const input: Ref = ref();
		const container = ref();
		const query = ref('');
		const index = ref(0);
		const active = ref(false);
		const projectUsers: Ref<User[]> = ref([]);

		const usersToShow: Ref<User[]> = ref([]);

		const maximumExceeded = computed(() => {
			return props.max && props.modelValue?.length >= props.max;
		});

		// get the users from the store on mounted
		onMounted(async () => {
			projectUsers.value = Store.get('users')?.value;

			if (props.restricted) {
				const projectId = Number(route.params.id);
				await ProjectService.show(projectId).then((response) => {
					if (response.members) {
						const newMembers: User[] = [];
						response.members.forEach((member) => {
							if (member?.member?.role !== 'BOARDMEMBER' && member?.member?.role !== 'STAKEHOLDER') {
								newMembers.push(member);
							}
						});
						projectUsers.value = newMembers;
					}
				});
			}

			load(props.modelValue);
		});

		// get the users from the store when the users change
		watch(Store.get('users'), (value) => {
			if (!props.restricted) {
				projectUsers.value = value;
			}
		});

		watch(
			() => props.setFocus,
			(setFocus) => {
				if (setFocus) {
					nextTick(() => {
						input.value.focus();
					});
				}
			}
		);

		watch(
			() => props.modelValue,
			(value) => {
				load(value);
			}
		);

		watch(active, (value) => {
			if (!value) {
				container.value.scrollTo(0, 0);
			}
		});

		watch(query, () => {
			index.value = 0;

			scroll();
		});

		// Loading users
		function load(data: Array<number>) {
			if (!data || !data.length) {
				usersToShow.value = [];
				return;
			}

			if (!projectUsers.value) {
				return;
			}

			let usersId: number[] = [];
			const usersToShowTemp: User[] = [];

			for (const userId of data) {
				if (isNaN(userId)) {
					continue;
				}

				usersId = [...usersId, userId];

				projectUsers.value.forEach((user: User) => {
					if (user.id === userId) {
						usersToShowTemp.push(user);
					}
				});
			}

			usersToShow.value = usersToShowTemp;
		}

		// get user's full name
		function getFullName(user: User) {
			return `${user.first_name} ${user.last_name}`;
		}

		// list of users that are included in the search result
		const users = computed(() => {
			if (!projectUsers.value) {
				return [];
			}

			const lowerCaseQuery = query.value.toLowerCase();

			// filter out the users that are already selected
			const usersNotSelected = projectUsers.value.filter((user: User) => {
				return !props.modelValue?.includes(user.id);
			});

			// filter out the users that do not match the query
			return usersNotSelected.filter((user: User) => getFullName(user).toLowerCase().includes(lowerCaseQuery));
		});

		// add a user to the list
		function selectUser(user: User) {
			// if max is exceeded
			if (props.max && props.modelValue?.length >= props.max) {
				return;
			}

			if (!user) {
				return;
			}

			ctx.emit('add', user);

			ctx.emit('update', [...(props.modelValue || []), user.id]);

			input.value.focus();

			query.value = '';
			index.value = 0;
		}

		function scroll() {
			nextTick(() => {
				const parent = container.value;

				// The active element
				const active = parent.querySelector('[data-active="true"]');

				if (!active) {
					return;
				}

				const height = active.offsetHeight;
				const top = active.offsetTop;

				const parentHeight = parent.offsetHeight;
				const parentScrollTop = parent.scrollTop;

				if (top + height > parentScrollTop + parentHeight) {
					parent.scroll({ top: parentScrollTop + height });
				}

				if (top + height < parentScrollTop + height) {
					parent.scroll({ top: parentScrollTop - height });
				}
			});
		}

		function removeUser(userToRemove: User) {
			if (user.value.cannot('update projects')) {
				return;
			}

			ctx.emit('remove', userToRemove.id);

			ctx.emit(
				'update',
				props.modelValue?.filter((userId: number) => userId !== userToRemove.id)
			);

			nextTick(() => {
				input.value.focus();
			});
		}

		function selectUserAtCurrentIndex() {
			selectUser(users.value[index.value]);
		}

		function keydown(e: KeyboardEvent) {
			if (e.key === 'Backspace') {
				if (query.value !== '') {
					return;
				}

				if (!props.modelValue) {
					return;
				}

				ctx.emit('update', props.modelValue.slice(0, props.modelValue.length - 1));
			}
		}

		function up() {
			if (index.value <= 0) {
				return;
			}

			index.value--;

			scroll();
		}

		function down() {
			if (index.value >= users.value?.length - 1) {
				return;
			}

			index.value++;

			scroll();
		}

		function isExternalUser(userRoles: Array<string>) {
			let returnValue = false;
			if (userRoles) {
				userRoles.forEach((role) => {
					if (role === 'External user') {
						returnValue = true;
					}
				});
			}

			return returnValue;
		}

		return {
			// data
			uid: getCurrentInstance()?.uid || '',
			input,
			container,
			query,
			index,
			active,
			usersToShow,

			// computed
			users,
			maximumExceeded,

			// functions
			getFullName,
			selectUser,
			removeUser,
			selectUserAtCurrentIndex,
			keydown,
			up,
			down,
			isExternalUser,
		};
	},
});
