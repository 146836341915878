import axios from 'axios';

import TaskGroup from '@/models/task-group.model';

import CreateTaskGroupRequest from '@/requests/create-task-group.request';
import UpdateTaskGroupRequest from '@/requests/update-task-group.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class TaskGroupService {
	public static async get(projectId: number): Promise<TaskGroup[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects/${projectId}/task-groups`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar bólkarnar til verkætlanina við id ${projectId}`,
					error
				);

				Toast.error('Tað eydnaðist ikki at heinta bólk');

				return false;
			});
	}

	public static async create(createTaskGroupRequest: CreateTaskGroupRequest): Promise<TaskGroup> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/task-groups`, createTaskGroupRequest)
			.then((response) => {
				Toast.success('Stovna bólk', 'Bólkur er stovnaður');
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna bólkin', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateTaskGroupRequest: UpdateTaskGroupRequest): Promise<TaskGroup> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/task-groups/${id}`, updateTaskGroupRequest)
			.then((response) => {
				Toast.success('Dagfør bólk', 'Bólkurin er dagførdur');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta bólkin við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/task-groups/${id}`)
			.then((response) => {
				Toast.success('Bólkur strikaður', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika bólkin við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
