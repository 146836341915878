
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, Ref, ref } from 'vue';

import SVG from '@/components/SVG.vue';

type Option = {
	key: string;
	value: string;
};

export default defineComponent({
	props: ['modelValue', 'options', 'label', 'title', 'borderless', 'noMin'],

	components: {
		SVG,
	},

	setup(props, ctx) {
		const active = ref(false);
		const container: Ref = ref(null);

		const index = ref(0);
		const minIndex = ref(0);

		const selected = computed(() => {
			if (!props.modelValue) {
				return null;
			}

			return props.options.find((option: Option) => option.key == props.modelValue);
		});

		onMounted(() => {
			setValue(props.modelValue);

			if (!props.title) {
				minIndex.value = 1;
				index.value = 1;
			}
		});

		function setValue(id: string | null) {
			ctx.emit('update:modelValue', id);

			index.value = props.options.findIndex((option: Option) => option.key == id) + 1;
		}

		// Scroll container when navigating with the arrow keys
		function scroll() {
			nextTick(() => {
				const parent = container.value;

				// The active element
				const active = parent.querySelector('[data-active="true"]');

				const height = active.offsetHeight;
				const top = active.offsetTop;

				const parentHeight = parent.offsetHeight;
				const parentScrollTop = parent.scrollTop;

				if (top + height > parentScrollTop + parentHeight) {
					parent.scroll({ top: parentScrollTop + height });
				}

				if (top + height < parentScrollTop + height) {
					parent.scroll({ top: parentScrollTop - height });
				}
			});
		}

		function selectCurrentIndex() {
			if (index.value == 0) {
				setValue(null);

				return;
			}

			const option: Option = props.options[index.value - 1];

			setValue(option.key);
		}

		function up() {
			if (index.value === minIndex.value) {
				return;
			}

			index.value--;

			scroll();

			selectCurrentIndex();
		}

		function down() {
			if (index.value >= props.options?.length) {
				return;
			}

			index.value++;

			scroll();

			selectCurrentIndex();
		}

		return {
			// data
			uid: getCurrentInstance()?.uid || '',
			active,
			index,
			container,
			selected,

			// functions
			setValue,
			selectCurrentIndex,
			up,
			down,
		};
	},
});
