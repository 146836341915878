
import { defineComponent } from 'vue';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	props: {
		title: String,
		large: Boolean,
	},

	emits: ['add'],

	components: {
		SVG,
	},
});
