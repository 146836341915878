import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["data-active"]
const _hoisted_4 = ["innerHTML", "data-active", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", {
    onKeydown: [
      _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.up && _ctx.up(...args)), ["prevent"]), ["up"])),
      _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.down && _ctx.down(...args)), ["prevent"]), ["down"])),
      _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.selectCurrentIndex && _ctx.selectCurrentIndex(...args)), ["prevent"]), ["enter"])),
      _cache[6] || (_cache[6] = _withKeys(($event: any) => {
			_ctx.$refs.checkbox.blur();
			_ctx.active = false;
		}, ["esc"])),
      _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.active = false), ["enter"]))
    ],
    class: _normalizeClass({ 'ring-blue-200 focus-within:ring-1': _ctx.borderless })
  }, [
    _withDirectives(_createElementVNode("label", {
      for: `select-${_ctx.uid}`,
      class: "block mb-[6px] text-gray-700 text-sm font-semibold"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1), [
      [_vShow, _ctx.label]
    ]),
    _withDirectives(_createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.active = !_ctx.active)),
      class: _normalizeClass(["relative flex flex-start items-center min-w-[128px] w-full h-10 rounded-md cursor-pointer ring-blue-200 focus-within:ring-1", { 'bg-white border border-dark-100': !_ctx.borderless, 'min-w-min': _ctx.noMin }])
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        ref: "checkbox",
        onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.active = false), ["tab"])),
        class: "opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
      }, null, 544),
      _createElementVNode("div", {
        class: "px-3 text-gray-500 text-sm cursor-pointer",
        for: "",
        innerHTML: _ctx.selected?.value || _ctx.title
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(["absolute right-[18px] top-1/2 transition transform -translate-y-1/2 rotate-90 h-4 w-1.5 text-gray-500", _ctx.active ? '-rotate-90' : ''])
      }, [
        _createVNode(_component_SVG, { name: "icons/chevron" })
      ], 2),
      _createElementVNode("div", {
        ref: "container",
        class: _normalizeClass(["z-20 absolute top-full w-full max-h-60 py-1 bg-white border border-dark-100 rounded-md transform transition overflow-y-auto", 
					_ctx.active
						? 'translate-y-1 opacity-1 pointer-events-all'
						: '-translate-y-1 opacity-0 pointer-events-none'
				]),
        tabindex: "-1"
      }, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              "data-active": _ctx.index == 0,
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
						_ctx.setValue(null);
						_ctx.active = false;
					}, ["stop"])),
              class: _normalizeClass(["flex flex-start items-center h-7 px-3 text-gray-400 hover:bg-gray-100 text-sm", { 'bg-gray-50': _ctx.selected == null, 'bg-gray-100': _ctx.index == 0 }])
            }, _toDisplayString(_ctx.title), 11, _hoisted_3))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            innerHTML: option.value,
            "data-active": _ctx.index == i + 1,
            onClick: _withModifiers(($event: any) => {
						_ctx.setValue(option.key);
						_ctx.active = false;
					}, ["stop"]),
            class: _normalizeClass(["flex flex-start items-center py-2 px-3 hover:bg-gray-100 text-sm", { 'bg-gray-50': _ctx.selected?.key == option.key, 'bg-gray-100': _ctx.index == i + 1 }])
          }, null, 10, _hoisted_4))
        }), 128))
      ], 2)
    ], 2), [
      [_directive_click_away, () => (_ctx.active = false)]
    ])
  ], 34))
}