import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["id", "placeholder", "disabled"]
const _hoisted_4 = ["data-active", "onClick"]
const _hoisted_5 = { class: "py-[2px] px-3" }
const _hoisted_6 = { class: "py-[6px] px-3 text-gray-400 font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group relative", { 'mb-4': !_ctx.noMargin }])
  }, [
    _withDirectives(_createElementVNode("label", {
      for: `person-input-${_ctx.uid}`,
      class: "block mb-[6px] text-gray-700 text-sm font-semibold"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1), [
      [_vShow, _ctx.label]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-wrap w-full py-[2px] px-3 text-sm rounded-md outline-none cursor-text", { 'bg-white border border-[#e2e2e2] ': !_ctx.borderless, 'bg-gray-200 rounded-none': _ctx.disabled }]),
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.input.focus()))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersToShow, (user, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `user-${index}`,
          onClick: _withModifiers(($event: any) => (_ctx.removeUser(user)), ["stop"]),
          class: _normalizeClass(["flex items-center my-1 mr-2 py-[2px] px-3 text-green-800 bg-green-200 hover:bg-green-300 text-sm font-bold rounded-full cursor-pointer transition", { 'text-gray-800 bg-gray-200 hover:bg-gray-300': _ctx.isExternalUser(user.role_names) }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.getFullName(user)) + " ", 1),
          (_ctx.$user.can('update projects'))
            ? (_openBlock(), _createBlock(_component_SVG, {
                key: 0,
                name: "icons/add",
                class: "w-3 h-3 ml-1.5 transform rotate-45"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      }), 128)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        ref: "input",
        id: `person-input-${_ctx.uid}`,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        onKeydown: [
          _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args))),
          _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.up && _ctx.up(...args)), ["prevent"]), ["up"])),
          _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.down && _ctx.down(...args)), ["prevent"]), ["down"])),
          _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.selectUserAtCurrentIndex && _ctx.selectUserAtCurrentIndex(...args)), ["prevent"]), ["enter"])),
          _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.active = false), ["stop"]), ["escape"])),
          _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.active = false), ["tab"]))
        ],
        onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.active = true)),
        style: _normalizeStyle(`width: ${_ctx.query.length + 1}ch;`),
        class: "bg-transparent outline-none py-[6px]"
      }, null, 44, _hoisted_3), [
        [_vModelText, _ctx.query],
        [_directive_click_away, () => (_ctx.active = false)]
      ])
    ], 2),
    _createElementVNode("div", {
      ref: "container",
      tabindex: "-1",
      class: _normalizeClass(["absolute z-30 w-full min-w-[256px] max-h-32 mt-1 bg-white text-sm rounded shadow overflow-auto transition", { 'opacity-0 pointer-events-none -translate-y-2': !_ctx.active || _ctx.maximumExceeded }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: user,
          "data-active": _ctx.index == i,
          onClick: _withModifiers(($event: any) => (_ctx.selectUser(user)), ["prevent"]),
          class: _normalizeClass(["block py-2 pl-3 pr-6 whitespace-nowrap hover:bg-gray-100 cursor-pointer", { 'bg-gray-50': _ctx.index == i }])
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getFullName(user)), 1)
        ], 10, _hoisted_4))
      }), 128)),
      _withDirectives(_createElementVNode("div", _hoisted_6, "Ongin brúkari varð funnin.", 512), [
        [_vShow, !_ctx.users.length]
      ])
    ], 2)
  ], 2))
}