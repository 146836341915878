
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';

import Add from '@/components/draggable-table/Add.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import SVG from '@/components/SVG.vue';

export default defineComponent({
	emits: ['update:title', 'select-all', 'add', 'delete', 'update-title'],

	props: {
		title: {
			type: String,
			required: true,
		},
		headings: Array,
		add: String,
		empty: Boolean,
		multiSelect: Boolean,
		canEditTitle: Boolean,
		canDelete: Boolean,
		shouldSelectAll: Boolean,
	},

	components: {
		Add,
		Checkbox,
		SVG,
	},

	setup(props, ctx) {
		const input = ref(null as HTMLInputElement | null);

		const shouldSelect = ref(false);

		const contextMenu = ref({
			active: false,
		});

		onMounted(() => {
			nextTick(() => {
				if (!input.value) {
					return;
				}

				input.value.value = props.title;
			});
		});

		watch(
			() => props.shouldSelectAll,
			(shouldSelectAll) => {
				shouldSelect.value = shouldSelectAll;
			}
		);

		function selectAll() {
			ctx.emit('select-all', !props.shouldSelectAll);
		}

		return {
			// data
			input,
			contextMenu,
			shouldSelect,

			// functions
			selectAll,
		};
	},
});
